<template>
  <div>
    <b-container>
      <b-row>
        <b-col
          md="12"
          class="text-center"
        >
          <h4>Devam etmek için hesap türünüzü seçin</h4>
        </b-col>
      </b-row>
      <b-row
        class="vh-30 text-center"
        align-v="center"
      >
        <b-col
          md="6"
          class="px-4 py-1"
        >
          <b-button
            variant="outline-primary"
            @click="applicantBtnClicked"
          >
            <div class="header-profile-sidebar">
              <div class="avatar box-shadow-1 avatar-xl avatar-border">
                <b-avatar
                  size="70"
                  src="@/assets/images/applicant.png"
                />
              </div>
              <h2 class="chat-user-name">
                Aday
              </h2>
              <span class="user-post text-capitalize">İş arıyorum.</span>
            </div>
          </b-button>
        </b-col>
        <b-col
          md="6"
          class="px-4 py-1"
        >
          <b-button
            variant="outline-primary"
            @click="employerBtnClicked"
          >
            <div class="header-profile-sidebar">
              <div class="avatar box-shadow-1 avatar-xl avatar-border">
                <b-avatar
                  size="70"
                  src="@/assets/images/employer-profile.png"
                />
              </div>
              <h2 class="chat-user-name">
                İşveren
              </h2>
              <span class="user-post text-capitalize">İşçi arıyorum.</span>
            </div>
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>

import {
  BContainer, BRow, BCol, BAvatar, BButton,
} from 'bootstrap-vue'

const SignUpType = {
  Applicant: 0,
  Employer: 1,
}

export { SignUpType }

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BAvatar,
    BButton,
  },
  data() {
    return {
      selectedSignUpType: -1,
    }
  },
  watch: {
    selectedSignUpType() {
      this.$emit('signUpTypeChanged', this.selectedSignUpType)
    },
  },
  methods: {
    applicantBtnClicked() {
      this.selectedSignUpType = SignUpType.Applicant
    },
    employerBtnClicked() {
      this.selectedSignUpType = SignUpType.Employer
    },
  },
}
</script>
